import React from "react";
import 'react-toastify/dist/ReactToastify.css';
import Home from "./home";

class IndexPage extends React.Component {
  render() {
    return(
      <Home/>
    )
  }
}
export default IndexPage;
